import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Vimeo from '@vimeo/player';
import { getEnvVar } from 'utils/get-env-var';

import { Modal, ModalHeader, ModalWrapper, ModalClose, ModalBody as BaseModalBody } from 'components/_core/Modal';
import Button from 'components/_core/Button';
import InlineLoader from 'components/_core/InlineLoader';
import CourseOutline from './CourseOutline';
import CourseFetcher from './CourseFetcher';
import ProgressCircle from 'components/_core/ProgressCircle';
import { SubscribeBtn } from 'components/buttons/SubscribeBtn';

const TopContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

const ModalBody = styled(BaseModalBody)`
    border-radius: 0 0 5px 5px;
`;

const Description = styled.div`
    h3 {
        color: ${props => props.theme.colors.lightGrayText};
        margin: 0 0 15px 0;
        font-size: 30px;
        font-weight: 600;

        @media screen and (max-width: 500px) {
            font-size: 20px
        }
    }

    p {
        color: ${props => props.theme.colors.lightGray};
        margin: 5px 0;
    }
`;

const PreviewPanel = styled.div`
    flex-basis: 300px;
    flex-shrink: 0;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.offWhite};
    padding: 10px;
    text-align: center;

    ${Button} {
        width: 100%;
        padding: 20px 30px;
    }

    @media screen and (max-width: 700px) {
        margin: 15px 0 0 0;
    }
`;

const PreviewThumb = styled.div`
    cursor: pointer;
    position: relative;

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
        transition: background-color 0.3s;
    }

    &:after {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: rgba(255, 255, 255, 0.8);
        transition: border-left-color 0.3s;

        border-width: 15px;
        border-left-width: 20px;
        margin-left: 10px;
    }

    &:hover {
        &:before {
            background-color: rgba(0, 0, 0, 0.85);
        }

        &:after {
            border-left-color: rgba(255, 255, 255, 1);
        }
    }

    img {
        width: 100%;
    }
`;

const PreviewText = styled.p`
    color: ${props => props.theme.colors.lightGrayText};
    margin: 0 0 10px;
    font-weight: 600;
    font-size: 12px;
`;

const VideoPreview = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    
    transition: opacity 0.3s;

    #preview-wrap {
        height: 100%;
    }
 
    ${props => {
        if (props.show) {
            return `
                opacity: 1;
                pointer-events: all;
            `;
        } else {
            return `
                opacity: 0;
                pointer-events: none;
            `;
        }
    }}

    #preview-wrap {
        pointer-events: none;
        
        iframe {
            width: 100%;
            height: 100%;
        }
    }
`;

const PlayPauseBtn = styled.button`
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 0 0 20px 20px;

    border: 0;
    outline: 0;
    background: none;
    color: #fff;

    &:before, &:after {
        content: '';
        display: inline-block;
    }

    &:before {
        width: 0;
    }

    ${props => {
        if (props.playing) {
            return `
                &:before, &:after {
                    border-left: 7px solid #fff;
                    height: 20px;
                    margin: 0 3px;
                }
            `;
        } else {
            return `
                &:before {
                    border: solid transparent;
                    height: 0;

                    border-color: rgba(255, 255, 255, 0);
                    border-left-color: #fff;

                    border-width: 10px;
                    border-left-width: 15px;
                    margin: 0 3px;
                }

                &:after {
                    opacity: 0;
                }
            `;
        }
    }}
`;

const ExitPreviewBtn = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;

    padding: 8px 12px;
    font-size: 12px;

    border: 0;
    outline: 0;
    background-color: rgba(50, 50, 50, 0.5);
    border-radius: 5px;
    color: #fff;

    cursor: pointer;

    &:hover {
        background-color: rgba(50, 50, 50, 0.8);
    }
`;


export default class CourseModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    openPreview(videoId) {
        const opts = {
            id: videoId,
            autoplay: true,
            width: '100%',
            height: '100%',
            controls: false
        };

        this.player = new Vimeo(this.previewPlayerTarget, opts);
        this.setState({ playingPreview: true });

        this.props.playCoursePreview();
    }

    exitPreview() {
        if (this.player) {
            this.player.destroy();
        }

        this.props.stopCoursePreview();
    }

    playPause() {
        if (this.state.playingPreview) {
            this.pausePreview();
        } else {
            this.playPreview();
        }
    }

    pausePreview() {
        if (!this.player) {
            return;
        }

        this.setState({ playingPreview: false });

        this.player.pause();
    }

    playPreview() {
        if (!this.player) {
            return;
        }

        this.setState({ playingPreview: true });

        this.player.play();
    }

    copyCourseAndClose() {
        this.props.copyModalCourse(); // copy the loaded course from the modal to the current course reducer
        this.props.closeCourseModal();
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const course = this.props.course || {};
        const previewVideoId = course.previewVideoId;
        
        return (
            <Modal open={this.props.open} onClick={() => this.props.closeCourseModal()}>
                <ModalWrapper onClick={e => e.stopPropagation()} style={{ borderRadius: '5px', overflow: 'hidden' }}>
                    <ModalHeader style={{ padding: '7px 20px'}}>
                        <ProgressCircle percent={course.percent_complete} />
                        <h2 style={{marginLeft: '10px'}}>{this.props.courseName}</h2>
                        <ModalClose onClick={() => this.props.closeCourseModal()}>&times;</ModalClose>
                    </ModalHeader>
                    <ModalBody>
                        <CourseFetcher slug={this.props.courseSlug} loader={<InlineLoader text="Loading Course" dark />}>
                            <TopContent>
                                <Description>
                                    <h3>Description</h3>
                                    <p dangerouslySetInnerHTML={{ __html: course.description }} />
                                </Description>
                                <PreviewPanel>
                                    { previewVideoId && <><PreviewThumb onClick={() => this.openPreview(previewVideoId)}><img src={`${getEnvVar('UPLOAD_PATH')}/thumb_${course.photo}`} alt="Course Preview" /></PreviewThumb>
                                    <PreviewText>Watch Introduction</PreviewText></>}
                                    {this.props.isSubscriber && <Button as={Link} onClick={() => this.copyCourseAndClose()} to={`${getEnvVar('ROOT_ROUTE')}/course/${course.slug}`} color="teal">Go to course</Button>}
                                    {!this.props.isSubscriber && <SubscribeBtn />}
                                    {!this.props.isSubscriber && !this.props.loggedIn && <p style={{ color: '#999', fontSize: '13px', marginTop: '10px' }}>Already a subscriber? <span style={{ cursor: 'pointer'}} onClick={() => this.props.toggleLoginModal()}>Login</span></p>}
                                </PreviewPanel>
                                <VideoPreview show={this.props.playingPreview}>
                                    <div id="preview-wrap" ref={ref => this.previewPlayerTarget = ref}></div>
                                    <ExitPreviewBtn onClick={() => this.exitPreview()}>Exit Preview</ExitPreviewBtn>
                                    <PlayPauseBtn playing={this.state.playingPreview} onClick={() => this.playPause()} />
                                </VideoPreview>
                            </TopContent>
                        </CourseFetcher>
                    </ModalBody>
                    {course.contents && <CourseOutline chapters={course.contents} />}
                </ModalWrapper>
            </Modal>
        );
    }
}
